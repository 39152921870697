<script setup lang="ts">

</script>

<template>
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8033 19.2179H28.0192C28.3029 19.9948 28.458 20.8333 28.458 21.7072V32.2295C28.458 32.5939 28.3946 32.9436 28.2792 33.2689H32.8819C34.6013 33.2689 36 31.8701 36 30.1508V24.4146C36.0001 21.5492 33.6688 19.2179 30.8033 19.2179Z" fill="url(#paint0_linear_521_11326)"/>
<path d="M7.54208 21.7073C7.54208 20.8333 7.69712 19.9949 7.98091 19.2179H5.19681C2.33129 19.2179 0 21.5492 0 24.4147V30.1509C0 31.8702 1.39873 33.269 3.11808 33.269H7.72089C7.60551 32.9436 7.54208 32.5939 7.54208 32.2296V21.7073Z" fill="url(#paint1_linear_521_11326)"/>
<path d="M21.1825 16.5105H14.8176C11.9521 16.5105 9.62081 18.8418 9.62081 21.7073V32.2296C9.62081 32.8035 10.0861 33.2689 10.6602 33.2689H25.3399C25.9139 33.2689 26.3793 32.8036 26.3793 32.2296V21.7073C26.3793 18.8418 24.048 16.5105 21.1825 16.5105Z" fill="url(#paint2_linear_521_11326)"/>
<path d="M18 2.73096C14.5539 2.73096 11.7502 5.5346 11.7502 8.98084C11.7502 11.3184 13.0404 13.3599 14.9457 14.4318C15.8495 14.9401 16.8913 15.2306 18 15.2306C19.1087 15.2306 20.1506 14.9401 21.0543 14.4318C22.9597 13.3599 24.2498 11.3183 24.2498 8.98084C24.2498 5.53467 21.4462 2.73096 18 2.73096Z" fill="url(#paint3_linear_521_11326)"/>
<path d="M7.0255 8.55629C4.44819 8.55629 2.35147 10.653 2.35147 13.2303C2.35147 15.8076 4.44819 17.9044 7.0255 17.9044C7.67926 17.9044 8.30181 17.7689 8.86734 17.5255C9.84511 17.1045 10.6513 16.3594 11.1502 15.426C11.5003 14.7709 11.6995 14.0235 11.6995 13.2303C11.6995 10.6531 9.60281 8.55629 7.0255 8.55629Z" fill="url(#paint4_linear_521_11326)"/>
<path d="M28.9746 8.55629C26.3973 8.55629 24.3005 10.653 24.3005 13.2303C24.3005 14.0236 24.4997 14.7709 24.8499 15.426C25.3488 16.3594 26.155 17.1046 27.1327 17.5255C27.6983 17.7689 28.3208 17.9044 28.9746 17.9044C31.5519 17.9044 33.6486 15.8076 33.6486 13.2303C33.6486 10.653 31.5519 8.55629 28.9746 8.55629Z" fill="url(#paint5_linear_521_11326)"/>
<defs>
<linearGradient id="paint0_linear_521_11326" x1="18" y1="2.73096" x2="18" y2="33.269" gradientUnits="userSpaceOnUse">
<stop stop-color="#D28DFF"/>
<stop offset="1" stop-color="#9258B8"/>
</linearGradient>
<linearGradient id="paint1_linear_521_11326" x1="18" y1="2.73096" x2="18" y2="33.269" gradientUnits="userSpaceOnUse">
<stop stop-color="#D28DFF"/>
<stop offset="1" stop-color="#9258B8"/>
</linearGradient>
<linearGradient id="paint2_linear_521_11326" x1="18" y1="2.73096" x2="18" y2="33.269" gradientUnits="userSpaceOnUse">
<stop stop-color="#D28DFF"/>
<stop offset="1" stop-color="#9258B8"/>
</linearGradient>
<linearGradient id="paint3_linear_521_11326" x1="18" y1="2.73096" x2="18" y2="33.269" gradientUnits="userSpaceOnUse">
<stop stop-color="#D28DFF"/>
<stop offset="1" stop-color="#9258B8"/>
</linearGradient>
<linearGradient id="paint4_linear_521_11326" x1="18" y1="2.73096" x2="18" y2="33.269" gradientUnits="userSpaceOnUse">
<stop stop-color="#D28DFF"/>
<stop offset="1" stop-color="#9258B8"/>
</linearGradient>
<linearGradient id="paint5_linear_521_11326" x1="18" y1="2.73096" x2="18" y2="33.269" gradientUnits="userSpaceOnUse">
<stop stop-color="#D28DFF"/>
<stop offset="1" stop-color="#9258B8"/>
</linearGradient>
</defs>
</svg>

</template>